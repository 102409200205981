import { Wallet } from "@dynamic-labs/sdk-react-core";

import { getUSDCContractAddress } from "..";
import { ContractTxDataMap, ContractFunc, WalletType } from "@/types";
import { USDC_ABI } from "@/constants";
import { BiconomySmartAccountV2, PaymasterMode } from "@biconomy/account";

interface WithdrawUsdcEOAArgs<TData> {
  primaryWallet: Wallet | null;
  txData: ContractTxDataMap[ContractFunc.WITHDRAW_USDC];
  setData: (data: TData | null) => void;
  setError: (error: Error | string | null) => void;
  setLoading: (loading: boolean) => void;
}

export const withdrawUsdcTxnEOA = async <TData>(
  args: WithdrawUsdcEOAArgs<TData>
) => {
  const { primaryWallet, txData, setData, setError, setLoading } = args;
  if (!primaryWallet) return;
  const { destinationAddress, amount } = txData;

  try {
    setError(null);
    setLoading(true);
    console.log({ mstatus: `trasnfer / EOA / loading`, txData });
    const walletClient = await primaryWallet?.getWalletClient();
    const receipt = await walletClient.writeContract({
      address: getUSDCContractAddress(),
      abi: USDC_ABI,
      functionName: "transfer",
      args: [destinationAddress, amount],
    });
    setData(receipt);
    console.log({ mstatus: `transfer / EOA / receipt`, receipt });
  } catch (error) {
    setError(`Error on transfer: ${error}`);
    console.error(`Error on transfer:`, error);
    return;
  } finally {
    setLoading(false);
  }
};

interface WithdrawUsdcTxnSAArgs<TData> {
  wallet: BiconomySmartAccountV2;
  txData: ContractTxDataMap[ContractFunc.WITHDRAW_USDC];
  setData: (data: TData | { success: boolean } | null) => void;
  setError: (error: Error | string | null) => void;
  setLoading: (loading: boolean) => void;
}

export const withdrawUsdcTxnSA = async <TData>(
  args: WithdrawUsdcTxnSAArgs<TData>
) => {
  const { wallet, txData, setData, setError, setLoading } = args;
  const { destinationAddress, amount } = txData;

  try {
    setError(null);
    setLoading(true);
    console.log({ mstatus: `trasnfer / SA / loading`, txData });

    const { wait } = await wallet.withdraw(
      [
        {
          address: getUSDCContractAddress(),
          amount: BigInt(amount),
          recipient: destinationAddress,
        },
      ],
      null,
      { paymasterServiceData: { mode: PaymasterMode.SPONSORED } }
    );

    const confirmations = await wait();
    const { success } = confirmations;

    if (success || success === "true") {
      setData({ success: true });
      console.log({
        mstatus: `trasnfer / SA / success`,
        success,
        confirmations,
      });
    } else {
      setError(`Error on transfer`);
      console.log({ mstatus: `trasnfer / SA / error`, success, confirmations });
    }
  } catch (error) {
    setError(`Error on transfer: ${error}`);
    console.error(`Error on transfer:`, error);
    return;
  } finally {
    setLoading(false);
  }
};
