import React from "react";
import { NavLink } from "react-router-dom";
import { authActions } from "@/store";
import {
  // AlignJustifyIcon,
  HomeIcon,
  UsersIcon,
  WalletIcon,
  LogOutIcon,
} from "lucide-react";

import BattleIcon from "@/assets/svg/battle.svg?react";
// import MobileMenuCurve from "@/assets/svg/mobile-menu-curve.svg?react";
import { Button } from "@/components/ui";
import { cn } from "@/helpers/tailwind";
import { ProfileScreens } from "@/pages/profile/components/Menu";
import { useDispatch } from "react-redux";

const navItems = [
  {
    namePath: "Home",
    path: "/",
    icon: <HomeIcon size={20} />,
  },
  {
    namePath: "Profile",
    path: `/profile?profileTab=${ProfileScreens.Battles}`,
    icon: <UsersIcon />,
  },
  {
    namePath: "Battles",
    path: "/battles",
    icon: <BattleIcon />,
  },
  {
    namePath: "Wallet",
    path: "/wallet",
    icon: <WalletIcon size={20} />,
  },
];

interface NavButtonProps {
  namePath: string;
  path: string;
  icon: React.ReactNode;
}

const NavButton: React.FC<NavButtonProps> = ({ namePath, path, icon }) => (
  <NavLink
    to={path}
    className={({ isActive }) =>
      cn("flex h-10 flex-col items-center justify-center p-0.5", {
        "rounded-3xl bg-slate-700 text-white": isActive,
      })
    }>
    {({ isActive }) => (
      <>
        <div className="xs:scale-100 scale-75">{icon}</div>
        {isActive ? (
          <div className="xs:h-3 flex h-4 w-12 items-center justify-center">
            <div className="h-1 w-1 rounded-full bg-white" />
          </div>
        ) : (
          <div className="flex h-4 w-12 items-center justify-center">
            <span className="truncate text-[10px]">{namePath}</span>
          </div>
        )}
      </>
    )}
  </NavLink>
);

const BottomMenu: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <div className="fixed bottom-0 z-40 flex w-full items-end justify-center bg-background px-2 pb-4 pt-0 sm:px-10 sm:pb-6 md:hidden">
      <div className="pointer-events-none absolute inset-x-0 bottom-full h-10 w-full bg-gradient-to-t from-background to-transparent" />
      <div className="w-full max-w-md">
        <div className="mx-auto h-3 w-full bg-background" />
        {/* <Button className="z-1 relative mx-auto mb-[-16px] flex size-9 items-center justify-center rounded-full border border-slate-500 bg-white">
          <AlignJustifyIcon />
        </Button>
        <MobileMenuCurve className="mx-auto mb-[-24px] h-6 text-background" /> */}
        <div className="flex h-12 items-center justify-between rounded-full border border-slate-500 px-6">
          <Button
            className="flex h-10 flex-col items-center justify-center gap-0 bg-transparent p-0.5 text-white hover:bg-transparent focus:bg-transparent"
            onClick={() => dispatch(authActions.toggleTriggerLogin())}>
            <LogOutIcon className="size-5" />
            <span className="h-4 w-12 text-[10px]">Log out</span>
          </Button>
          {navItems.slice(1, 2).map((item) => (
            <NavButton key={item.path} {...item} />
          ))}
          {/* <div className="w-12" /> */}
          {navItems.slice(2).map((item) => (
            <NavButton key={item.path} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BottomMenu;
