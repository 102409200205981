import {
  BiconomySmartAccountV2,
  PaymasterMode,
  Transaction,
} from "@biconomy/account";

import {
  ApproveUsdcArgs,
  ContractFunc,
  ContractTxDataMap,
  WalletType,
} from "@/types";
import { encodeFunctionData } from "viem";
import { getAbi, getContractAddress } from "..";
import { prepareTxnArgs } from ".";
import isEmpty from "lodash.isempty";

interface SubmitTxnSAArgs<TData> {
  functionName: ContractFunc;
  wallet: BiconomySmartAccountV2;
  walletAddress: `0x${string}`;
  txnSpendsMoney: boolean;
  txData?: ContractTxDataMap[ContractFunc];
  setData: (data: TData | null) => void;
  setError: (error: Error | string | null) => void;
  setLoading: (loading: boolean) => void;
}

export const submitTxnSA = async <TData>(args: SubmitTxnSAArgs<TData>) => {
  const {
    functionName,
    wallet,
    walletAddress,
    txnSpendsMoney,
    txData,
    setData,
    setError,
    setLoading,
  } = args;
  setError(null);
  setLoading(true);

  let approveTxn: Transaction | undefined;
  if (txnSpendsMoney) {
    // todo: remove this and include elsewhere check or update submitTxnEOA as well...
    if (Number((txData as ApproveUsdcArgs).amount) < 1) {
      setError(
        `Amount is required for function '${functionName}' but hasn't been provided.`
      );
      setLoading(false);
      return;
    }
    approveTxn = {
      to: getContractAddress(ContractFunc.APPROVE_USDC),
      data: encodeFunctionData({
        abi: getAbi(ContractFunc.APPROVE_USDC),
        functionName: ContractFunc.APPROVE_USDC,
        args: prepareTxnArgs<ContractFunc.APPROVE_USDC>(
          ContractFunc.APPROVE_USDC,
          walletAddress,
          { amount: (txData as ApproveUsdcArgs)?.amount }
        ),
      }),
    };
    console.log({
      mstatus: `${functionName} / created approveTxn`,
      approveTxn,
    });
  }

  try {
    const mainTxnArgs = prepareTxnArgs<typeof functionName>(
      functionName,
      walletAddress,
      { ...txData } as ContractTxDataMap[typeof functionName]
    );
    console.log({ mstatus: `${functionName} / SA / loading...`, mainTxnArgs });
    const mainTxn = {
      to: getContractAddress(functionName),
      data: encodeFunctionData({
        abi: getAbi(functionName),
        functionName: functionName as string,
        args: mainTxnArgs,
      }),
    };
    const txns = txnSpendsMoney
      ? [approveTxn as Transaction, mainTxn]
      : [mainTxn];
    const userOp = await wallet.sendTransaction(txns, {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
    });
    console.log({ userOp, txns });
    const { transactionHash } = await userOp.waitForTxHash();
    console.log({ transactionHash });
    const userOpReceipt = await userOp.wait();
    console.log({
      mstatus: `${functionName} / SA / success`,
      success: userOpReceipt.success,
      receipt: userOpReceipt.receipt,
      userOpReceipt,
    });
    if (userOpReceipt.success === "false" || !userOpReceipt.success) {
      throw new Error("Transaction failed.");
    }
    setData(userOpReceipt.receipt);
  } catch (error) {
    setError(`Error on ${functionName}: ${error}`);
    console.error({ mstatus: `${functionName} / SA / error`, error });
  } finally {
    setLoading(false);
  }
};
