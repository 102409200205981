import React from "react";
import { useSelector } from "react-redux";

import { useDynamicContext, Wallet } from "@dynamic-labs/sdk-react-core";

import { RootState } from "@/store";
import { ContractFunc, ContractTxDataMap, WalletType } from "@/types";
import { useWalletInstance } from "@/contexts/WalletContext";
import {
  submitTxnEOA,
  submitTxnSA,
  withdrawUsdcTxnEOA,
  withdrawUsdcTxnSA,
  getUsdcBalanceTxn,
} from "./transactions";
import { BiconomySmartAccountV2 } from "@biconomy/account";
import isNil from "lodash.isnil";
import { MutationStatus } from "@tanstack/react-query";
import { Hex } from "viem";

type Web3QueryFunction<T> = (args?: T) => Promise<void>;

type UseWeb3QueryReturn<T extends ContractFunc, TData> = [
  Web3QueryFunction<ContractTxDataMap[T]>,
  {
    loading: boolean;
    error: Error | string | null;
    data: TData | { success: boolean } | null;
    status: MutationStatus;
  },
];

interface OtherArgs<TData> {
  onSuccess?: (data: TData | { success: boolean } | null) => void;
  onError?: (error: Error | string | null) => void;
}

const useWeb3Query = <TData>(
  functionName: ContractFunc,
  otherArgs?: OtherArgs<TData>
): UseWeb3QueryReturn<typeof functionName, TData> => {
  const { walletInstance } = useWalletInstance();
  const { walletAddress, walletType } = useSelector(
    (state: RootState) => state.wallet
  );
  const { primaryWallet } = useDynamicContext();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | string | null>(null);
  const [data, setData] = React.useState<TData | { success: boolean } | null>(
    null
  );
  const status = React.useMemo<MutationStatus>(() => {
    if (error) return "error";
    if (loading) return "pending";
    if (data) return "success";
    return "idle";
  }, [error, loading, data]);

  React.useEffect(() => {
    if (!isNil(data)) {
      otherArgs?.onSuccess?.(data);
    }
  }, [data]);

  React.useEffect(() => {
    if (!isNil(error)) {
      console.error(error);
      otherArgs?.onError?.(error);
    }
  }, [error]);

  const getUSDCBalance = React.useCallback(
    async () =>
      await getUsdcBalanceTxn<TData>({
        primaryWallet,
        walletAddress,
        setData,
        setError,
        setLoading,
      }),
    [primaryWallet, walletInstance, walletAddress, walletType]
  );

  const submitTransaction = async (
    txData?: ContractTxDataMap[typeof functionName]
  ): Promise<void> => {
    if (!walletInstance || !walletAddress) {
      console.log({
        mstatus: "No wallet instance or wallet address found.",
        functionName,
        walletInstance,
        walletAddress,
      });
      return;
    }

    if (functionName === ContractFunc.GET_USDC_BALANCE) {
      return await getUSDCBalance();
    } else if (functionName === ContractFunc.WITHDRAW_USDC) {
      if (walletType === WalletType.EOA) {
        return await withdrawUsdcTxnEOA<TData>({
          primaryWallet,
          txData: txData as ContractTxDataMap[ContractFunc.WITHDRAW_USDC],
          setData,
          setError,
          setLoading,
        });
      } else {
        return await withdrawUsdcTxnSA<TData>({
          wallet: walletInstance as BiconomySmartAccountV2,
          txData: txData as ContractTxDataMap[ContractFunc.WITHDRAW_USDC],
          setData,
          setError,
          setLoading,
        });
      }
    }

    const txnSpendsMoney = [
      ContractFunc.MAKE_PREDICTION,
      ContractFunc.ADD_SIZE,
      ContractFunc.CREATE_BATTLE,
    ].includes(functionName);

    if (walletType === WalletType.EOA) {
      await submitTxnEOA<TData>({
        functionName,
        wallet: walletInstance as Wallet,
        walletAddress: walletAddress as Hex,
        txnSpendsMoney,
        txData,
        setData,
        setError,
        setLoading,
      });
    } else if (walletType === WalletType.SMART_ACCOUNT) {
      await submitTxnSA<TData>({
        functionName,
        wallet: walletInstance as BiconomySmartAccountV2,
        walletAddress: walletAddress as Hex,
        txnSpendsMoney,
        txData,
        setData,
        setError,
        setLoading,
      });
    }
  };

  return [submitTransaction, { loading, error, data, status }];
};

export default useWeb3Query;
