import React from "react";
import { useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";

import { getWalletInstance } from "@/helpers";
import { useWalletInstance } from "@/contexts/WalletContext";
import isNil from "lodash.isnil";

// TODO: figure out a better way of wallet management
// This is needed to refresh the wallet instance in redux store if the user
// refreshes the page and is still logged in
const useWalletInitialization = () => {
  const { walletInstance, setWalletInstance } = useWalletInstance();
  const { primaryWallet } = useDynamicContext();
  const isLoggedIn = useIsLoggedIn();

  React.useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const asyncCall = async () => {
      try {
        await getWalletInstance(primaryWallet, setWalletInstance);
      } catch (error) {
        console.error("Error getting wallet instance:", error);
      }
    };

    if (isLoggedIn && isNil(walletInstance)) {
      asyncCall();
      intervalId = setInterval(asyncCall, 500);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [primaryWallet, isLoggedIn, walletInstance]);
};

export default useWalletInitialization;
