import React from "react";
import { cn } from "@/helpers/tailwind";

interface HorizontalScrollListProps {
  children: React.ReactNode;
  className?: string;
  showFades?: boolean;
}

const HorizontalScrollList: React.FC<HorizontalScrollListProps> = ({
  children,
  className,
  showFades = false,
}) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [leftFadeOpacity, setLeftFadeOpacity] = React.useState(0);
  const [rightFadeOpacity, setRightFadeOpacity] = React.useState(1);

  const handleScroll = React.useCallback(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const scrollWidth = container.scrollWidth;
    const containerWidth = container.clientWidth;
    const maxScroll = scrollWidth - containerWidth;
    const scrollLeft = container.scrollLeft;
    const scrollRight = maxScroll - scrollLeft;

    if (scrollLeft >= 10) setLeftFadeOpacity(1);
    else if (scrollLeft < 10) setLeftFadeOpacity(0);

    if (scrollRight >= 10) setRightFadeOpacity(1);
    else if (scrollRight < 10) setRightFadeOpacity(0);
  }, []);

  React.useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const fadeClassNames =
    "w-8 pointer-events-none absolute inset-y-0 from-background to-transparent transition-opacity duration-500 ease-in-out";

  return (
    <div className={cn("relative flex-grow overflow-hidden", className)}>
      <div
        ref={scrollContainerRef}
        className="scrollbar-hide flex items-center gap-2 overflow-hidden overflow-x-auto">
        {children}
      </div>
      {/* horizontal fades */}
      {showFades && (
        <>
          <div
            className={cn("left-0 bg-gradient-to-r", fadeClassNames, {
              "opacity-100": leftFadeOpacity === 1,
              "opacity-0": leftFadeOpacity === 0,
            })}
          />
          <div
            className={cn("right-0 bg-gradient-to-l", fadeClassNames, {
              "opacity-100": rightFadeOpacity === 1,
              "opacity-0": rightFadeOpacity === 0,
            })}
          />
        </>
      )}
    </div>
  );
};

export default HorizontalScrollList;
