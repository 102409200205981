import React from "react";
import { ArrowUpIcon, DollarSignIcon } from "lucide-react";

import { Button, ButtonProps } from "@/components/main";
import { cn } from "@/helpers/tailwind";
import { useTranslate } from "@/hooks";

interface TriggerButtonProps extends Omit<ButtonProps, "type"> {
  type: "add" | "update";
}

const TriggerButton = React.forwardRef<HTMLButtonElement, TriggerButtonProps>(
  (props, ref) => {
    const t = useTranslate();
    const { type: dialogType, className, ...rest } = props;

    return (
      <Button
        ref={ref}
        type="button"
        className={cn(
          "rounded-lg bg-indigo-600 text-white hover:bg-indigo-700",
          className
        )}
        {...rest}>
        {dialogType === "add" && <DollarSignIcon className="size-5" />}
        {dialogType === "update" && <ArrowUpIcon className="size-5" />}
        <span>
          {dialogType === "add" && t("battles.select")}
          {dialogType === "update" && t("battles.add_size")}
        </span>
      </Button>
    );
  }
);

export default TriggerButton;
