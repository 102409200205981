import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Profile } from "@/types";

export interface ProfileState {
  data: Profile | null;
  isLoading: boolean;
  error: Error | string | null;
}

const initialState: ProfileState = {
  data: null,
  isLoading: false,
  error: null,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<ProfileState["data"]>) => {
      state.data = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<ProfileState["isLoading"]>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<ProfileState["error"]>) => {
      state.error = action.payload;
    },
    clearProfileState: () => initialState,
  },
});

export const actions = profileSlice.actions;

export default profileSlice.reducer;
