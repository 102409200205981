import { UserProfile } from "@dynamic-labs/sdk-react-core";

import { WalletType } from "@/types";
import { LoginTypesEnum } from "@dripit/constants";
import isEmpty from "lodash.isempty";

export const getLoginType = (
  walletType: WalletType,
  creds: UserProfile["verifiedCredentials"]
) => {
  if (walletType === WalletType.EOA) {
    return LoginTypesEnum.Wallet;
  }
  if (isEmpty(creds)) return -1;
  const cred = creds[creds.length - 1];
  if (cred?.format === "oauth") {
    if (cred?.oauthProvider === "google") {
      return LoginTypesEnum.Google;
    } else if (cred?.oauthProvider === "facebook") {
      return LoginTypesEnum.Facebook;
    } else if (cred?.oauthProvider === "instagram") {
      return LoginTypesEnum.Instagram;
    } else if (cred?.oauthProvider === "discord") {
      return LoginTypesEnum.Discord;
    } else if (cred?.oauthProvider === "telegram") {
      return LoginTypesEnum.Telegram;
    } else if (cred?.oauthProvider === "twitter") {
      return LoginTypesEnum.Twitter;
    } else if (cred?.oauthProvider === "spotify") {
      return LoginTypesEnum.Spotify;
    }
  }
  return LoginTypesEnum.Email;
};
