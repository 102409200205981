import { UserProfile, Wallet } from "@dynamic-labs/sdk-react-core";

import { client } from "@/api/base";
import { LoginResponse } from "@/types";
import store, { authActions, walletActions, profileActions } from "@/store";
import { getWalletInstance, getLoginType } from "@/helpers";
import { BiconomySmartAccountV2 } from "@biconomy/account";
import { toast } from "@/components/ui";
import { siweMessage } from "@dripit/constants";

export const getHasJWT = (): boolean => !!localStorage.getItem("access_token");
export const getJWT = () => localStorage.getItem("access_token");
export const setJWT = (authToken: string): void =>
  localStorage.setItem("access_token", authToken);

export const getHasRefreshToken = (): boolean =>
  !!localStorage.getItem("refresh_token");
export const getRefreshToken = () => localStorage.getItem("refresh_token");
export const setRefreshToken = (refreshToken: string): void =>
  localStorage.setItem("refresh_token", refreshToken);
export const deleteAuthTokens = (): void => {
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("access_token");
};

interface UserMetadata {
  "Beta Code"?: string;
  "Referral Code"?: string;
}

interface AuthSuccessArgs {
  handleLogOut: () => Promise<void>;
  isAuthenticated: boolean;
  primaryWallet: Wallet | null;
  user: UserProfile;
  sigMessage: string | null;
  authSignature: string | null;
}
interface AuthSuccessCustomArgs {
  toast: typeof toast;
  setWalletInstance: (wallet: Wallet | BiconomySmartAccountV2 | null) => void;
}

export const handleAuthSuccess = async (
  args: AuthSuccessArgs,
  { toast, setWalletInstance }: AuthSuccessCustomArgs
) => {
  console.log({ mstatus: "onAuthSuccess args", ...args });
  const { user, authSignature, sigMessage, primaryWallet, handleLogOut } = args;

  try {
    const { walletAddress, walletType } = await getWalletInstance(
      primaryWallet,
      setWalletInstance
    );
    const isEmailUsed = await client.clearParams().post("is-email-used", {
      email: user.email,
      wallet: walletAddress,
    });

    let signInSig = authSignature ?? undefined;
    let signInMsg = sigMessage ?? undefined;
    const signerWallet = primaryWallet?.address;

    if (primaryWallet === null) {
      toast({ title: "Something went wrong when trying to authenticate" });
      store.dispatch(authActions.toggleTriggerLogin());
      handleLogOut();
    }

    if (authSignature === null && primaryWallet) {
      signInSig = await primaryWallet.signMessage(siweMessage);
      signInMsg = siweMessage;
    }

    const signedInUser = !isEmailUsed
      ? await client.post<LoginResponse>("users/create-new-user", {
          email: user.email,
          login_type: getLoginType(walletType, user?.verifiedCredentials),
          username: user.username,
          wallet: walletAddress,
          auth_identifier: user.userId,
          messageToSign: signInMsg,
          signedMessage: signInSig,
          signerWallet: signerWallet,
          refcode: (user?.metadata as UserMetadata)?.["Referral Code"] ?? "",
          invite_code: (user?.metadata as UserMetadata)?.["Beta Code"] ?? "",
        })
      : await client.post<LoginResponse>("auth/login", {
          email: user.email,
          auth_identifier: user.userId,
        });

    store.dispatch(authActions.setAccessToken(signedInUser.accessToken));
    store.dispatch(authActions.setRefreshToken(signedInUser.refreshToken));
  } catch (error) {
    toast({ title: "Something went wrong when trying to authenticate" });
    store.dispatch(authActions.toggleTriggerLogin());
    handleLogOut();
  }
};

export const handleAuthLogout = (): void => {
  store.dispatch(authActions.clearTokens());
  store.dispatch(profileActions.clearProfileState());
  store.dispatch(walletActions.clearWalletState());
};
