import isEmpty from "lodash.isempty";

// Matches the `pattern` in the `className`
// e.g. pattern: "rounded"     className="rounded-full"     will match "rounded-full"
// e.g. pattern: "flex"        className="2xl:flex"         will match "2xl:flex"
// e.g. pattern: "max-w-72"    className="lg:max-w-72"      will match "lg:max-w-72"
export const inheritClassName = (pattern: string, className?: string) => ({
  [`${className?.match(new RegExp(`(?:\\w+:)?${pattern}(?:-[a-z]+)?`)) ?? ""}`]:
    !isEmpty(className) && className?.includes(pattern),
});
