import React from "react";
import { UserIcon } from "lucide-react";

import { cn } from "@/helpers/tailwind";
import isEmpty from "lodash.isempty";
import { Skeleton } from "../ui";

const getAvatar = (src: string | null, alt: string, loading?: boolean) => {
  if (loading)
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Skeleton className="h-[75%] w-[75%] rounded-full bg-white/20" />
      </div>
    );
  if (!isEmpty(src))
    return (
      <img
        src={src as string}
        alt={alt}
        className="relative h-full w-full rounded-full"
      />
    );
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex h-[85%] w-[85%] items-center justify-center rounded-full bg-slate-200">
        <UserIcon className="h-[60%] w-[60%] stroke-1 text-slate-900" />
      </div>
    </div>
  );
};

interface Props {
  className?: string;
  src?: string | null;
  alt: string;
  badge?: React.ReactNode;
  ringClassName?: string;
  loading?: boolean;
}

const AvatarBadge: React.FC<Props> = ({
  className,
  src,
  alt,
  badge,
  ringClassName,
  loading,
}) => (
  <div className={cn("relative size-9", className)}>
    <div
      className={cn(
        "absolute inset-0 -m-0.5 rounded-full border border-blue-900",
        { hidden: !isEmpty(ringClassName) }
      )}
    />
    <div
      className={cn(
        "absolute inset-0 -m-1 rounded-full border border-dashed border-blue-500",
        ringClassName
      )}
    />
    {getAvatar(src ?? null, alt, loading)}
    {badge && (
      <div className="absolute -bottom-4 left-1/2 flex -translate-x-1/2 items-center justify-center">
        {badge}
      </div>
    )}
  </div>
);

export default AvatarBadge;
