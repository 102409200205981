import React, { Suspense, useState } from "react";
import { createBrowserRouter } from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createConfig, WagmiProvider } from "wagmi";
import { http } from "viem";
import { avalancheFuji } from "viem/chains";

import { routes } from "@/router/routes";
import SuspenseFallback from "@/components/layout/SuspenseFallback";
import { handleAuthSuccess, handleAuthLogout } from "./helpers";
import { useWalletInstance } from "./contexts/WalletContext";
import { useToast } from "./components/ui/use-toast";
import { siweMessage } from "@dripit/constants";

const router = createBrowserRouter(routes);
const evmNetworks = [
  {
    blockExplorerUrls: ["https://testnet.snowtrace.io/"],
    chainId: 43113,
    chainName: "Avalanche Fuji",
    iconUrls: ["https://app.dynamic.xyz/assets/networks/avax.svg"],
    name: "Avalanche Fuji",
    nativeCurrency: {
      decimals: 18,
      name: "Avax",
      symbol: "AVAX",
    },
    networkId: 43113,
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    vanityName: "Avalanche Fuji",
  },
];

const config = createConfig({
  chains: [avalancheFuji],
  multiInjectedProviderDiscovery: false,
  transports: {
    [avalancheFuji.id]: http(),
  },
});

const ContainerComponent: React.FC = () => {
  const { setWalletInstance } = useWalletInstance();
  const { toast } = useToast();
  const [authSignature, setAuthSignature] = useState<string | null>(null);
  const [sigMessage, setSigMessage] = useState<string | null>(null);

  return (
    <DynamicContextProvider
      settings={{
        environmentId: "d7677a9c-96be-4034-bc77-6ed585651435", // todo: move to .env
        siweStatement: siweMessage,
        overrides: { evmNetworks },
        walletConnectors: [EthereumWalletConnectors],
        events: {
          onAuthSuccess: async (data) =>
            await handleAuthSuccess(
              { ...data, sigMessage, authSignature },
              { toast, setWalletInstance }
            ),
          onLogout: () => {
            handleAuthLogout();
          },
          onSignedMessage: ({ messageToSign, signedMessage }) => {
            if (messageToSign.includes(siweMessage)) {
              setAuthSignature(signedMessage);
              setSigMessage(messageToSign);
            }
          },
        },
      }}>
      <WagmiProvider config={config}>
        <Suspense fallback={<SuspenseFallback />}>
          <DynamicWagmiConnector>
            <RouterProvider router={router} />
          </DynamicWagmiConnector>
        </Suspense>
      </WagmiProvider>
      {import.meta.env.VITE_HIDE_QUERY_TOOLS !== "true" && (
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-right"
        />
      )}
    </DynamicContextProvider>
  );
};

export default React.memo(ContainerComponent);
