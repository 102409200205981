import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { WalletType } from "@/types/wallet";

export interface WalletState {
  walletType: WalletType;
  walletAddress: null | `0x${string}`;
  balance: null | string;
}

const initialState: WalletState = {
  walletType: WalletType.NONE,
  walletAddress: null,
  balance: null,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWalletAddress: (state, action: PayloadAction<`0x${string}` | null>) => {
      state.walletAddress = action.payload;
    },
    setWalletType: (
      state,
      action: PayloadAction<WalletState["walletType"]>
    ) => {
      state.walletType = action.payload;
    },
    setBalance: (state, action: PayloadAction<WalletState["balance"]>) => {
      state.balance = action.payload;
    },
    clearWalletState: () => initialState,
  },
});

export const actions = walletSlice.actions;

export default walletSlice.reducer;
