import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import Root from "../root";
import ProtectedRoute from "@/components/auth/ProtectedRoute";
const SignInPage = lazy(() => import("@/pages/signin"));
const HomePage = lazy(() => import("@/pages/home"));
const BattlesPage = lazy(() => import("@/pages/battles"));
const BattleDetailsPage = lazy(
  () => import("@/pages/battles/battles-details-page")
);
// const ArenaDetailsPage = lazy(
//   () => import("@/pages/battles/Arena/arena-details-page/arena-details-page")
// );
const WalletPage = lazy(() => import("@/pages/wallet"));
const ProfilePage = lazy(() => import("@/pages/profile"));
const ErrorPage = lazy(() => import("@/pages/error"));

export const routes: RouteObject[] = [
  {
    path: "/signin",
    element: <SignInPage />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Root />
      </ProtectedRoute>
    ),
    children: [
      { index: true, element: <HomePage /> },
      { path: "battles", element: <BattlesPage /> },
      { path: "battles/weekly/:id", element: <BattleDetailsPage /> },
      // { path: "battles/arena/:id", element: <ArenaDetailsPage /> },
      { path: "wallet", element: <WalletPage /> },
      { path: "profile", element: <ProfilePage /> },
      { path: "*", element: <ErrorPage message="Not found" /> },
    ],
  },
];
