import React, { useEffect } from "react";
import CommentItem from "./CommentItem";
import { getBattleComments } from "@/api/battles/queries";
import { useParams } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";
import CenteredMessage from "../CenteredMessage";
import { useTranslate } from "@/hooks";
import { useQueryClient } from "@tanstack/react-query";

const CommentList: React.FC = () => {
  const t = useTranslate();
  const { id } = useParams();
  const { data, isLoading, isError } = getBattleComments(id, 1);

  if (isLoading)
    return (
      <div className="flex items-center space-x-4">
        <Skeleton className="h-12 w-12 rounded-full" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-[250px]" />
          <Skeleton className="h-4 w-[200px]" />
        </div>
      </div>
    );

  if (!data)
    return (
      <CenteredMessage
        message={t("components.no_comments")}
        icon="message-square"
      />
    );

  if (isError)
    return <CenteredMessage message={t("battles.error")} icon="circle-x" />;

  return (
    <div className="flex flex-col space-y-5">
      {data.results.map((comment, index) => (
        <CommentItem key={index} comment={comment} />
      ))}
    </div>
  );
};

export default React.memo(CommentList);
