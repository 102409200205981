import React from "react";
import { Separator } from "../../ui";
import { Smile, Trash2, Flag, Clock } from "lucide-react";
import { useTranslate } from "@/hooks";
import { getDiffFrom, resolveImage } from "@/helpers";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown";
import { BattleComment } from "@/types";

interface Props {
  comment: BattleComment;
}

const CommentItem: React.FC<Props> = ({ comment }) => {
  const t = useTranslate();

  return (
    <div className="flex">
      <img
        className="mr-2 h-12 w-12 rounded-lg"
        src={resolveImage(comment.user.avatar, "600x600")}
      />
      <div className="flex w-full flex-col">
        <div className="flex justify-between">
          <span className="text-xs text-gray-300">{comment.user.username}</span>
          <DropdownMenu>
            <DropdownMenuTrigger>...</DropdownMenuTrigger>
            <DropdownMenuContent>
              {/* TODO - Fix codnition when endpoint is ready */}
              {false && (
                <DropdownMenuItem>
                  <Trash2 />
                  {t("components.delete")}
                </DropdownMenuItem>
              )}
              <DropdownMenuItem>
                <Flag />
                {t("components.report")}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="text-xs text-gray-400">{comment.text}</div>
        <div className="mt-3 flex space-x-4 text-xs text-gray-400">
          {/* <Smile width={15} height={15} /> */}
          {/* <Separator orientation="vertical" /> */}
          <span className="text-xs text-gray-400">{t("components.reply")}</span>
          <Separator orientation="vertical" />
          <div className="flex space-x-2">
            <Clock width={15} height={15} />
            <span>{getDiffFrom(comment.created_at)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CommentItem);
