import { Wallet } from "@dynamic-labs/sdk-react-core";

import { getUSDCContractAddress } from "..";
import { USDC_ABI, USDC_DECIMALS } from "@/constants";
import { formatUnits } from "viem";

interface GetUsdcBalanceArgs<TData> {
  primaryWallet: Wallet | null;
  walletAddress: string | null;
  setData: (data: TData | null) => void;
  setError: (error: Error | string | null) => void;
  setLoading: (loading: boolean) => void;
}

export const getUsdcBalanceTxn = async <TData>({
  primaryWallet,
  setData,
  setError,
  setLoading,
  walletAddress,
}: GetUsdcBalanceArgs<TData>) => {
  if (!primaryWallet) return;

  try {
    setError(null);
    setLoading(true);
    const publicClient = await primaryWallet?.getPublicClient();
    const balance = await publicClient.readContract({
      address: getUSDCContractAddress(),
      abi: USDC_ABI,
      functionName: "balanceOf",
      args: [walletAddress],
    });
    const formattedBalance = formatUnits(balance, USDC_DECIMALS);
    setData(formattedBalance as TData);
  } catch (error) {
    setError(`Error on balanceOf: ${error}`);
    console.error(`Error on balanceOf:`, error);
    return;
  } finally {
    setLoading(false);
  }
};
