import React, { useEffect, useState } from "react";
import { Input } from "../../ui";
import Spotify from "@/assets/svg/spotify.svg?react";
import { Button } from "../../ui";
import { Smile, Send } from "lucide-react";
import { useTranslate } from "@/hooks";
import { Checkbox } from "../../ui";
import CommentList from "./CommentList";
import { postBattleComment } from "@/api/battles/queries";
import { useParams } from "react-router-dom";
import { CenteredMessage } from "../";
import { CommentPayload } from "@/types";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "@/api/constants";

const CommentSection: React.FC = () => {
  const t = useTranslate();
  const { id } = useParams();
  if (!id) return <CenteredMessage message={t("battles.error")} />;
  const [commentInput, setCommentInput] = useState("");
  const mutation = postBattleComment(id);
  const queryClient = useQueryClient();
  const handlePostComment = async () => {
    await mutation.mutateAsync({ comment: commentInput });
    setCommentInput("");
    queryClient.invalidateQueries({ queryKey: [queryKeys.BATTLE_COMMENTS] });
  };

  return (
    <div className="grid grid-cols-5 gap-5">
      <div className="comment-border col-span-4 pr-5">
        <div className="mb-4 flex w-full">
          <Input
            value={commentInput}
            placeholder={t("components.add_comment")}
            onChange={(event) => setCommentInput(event.target.value)}
          />
          {/* <Button variant="outline" onClick={() => {}}>
            <Smile />
          </Button> */}
          {/* <Button variant="outline" onClick={() => {}}>
            <Spotify width={"1.25rem"} height={"1.25rem"} className="block" />
          </Button> */}
          <Button
            disabled={!commentInput}
            className="bg-slate-500 text-white hover:text-gray-500"
            onClick={handlePostComment}>
            <Send /> {mutation.isPending ? "Sending..." : t("components.send")}
          </Button>
        </div>
        <CommentList />
      </div>
      {/* <div className="col-span-1 flex flex-col gap-3 divide-y">
        <div className="flex flex-col gap-3">
          <span className="">Filter by:</span>
          <span className="flex items-center">
            <Checkbox onChange={handleFilterChange} />
            <span className="pl-2">{t("components.all")}</span>
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="b mt-3 flex items-center">
            <Checkbox />
            <span className="pl-2">{t("components.holders")}</span>
          </span>
          <span className="b flex items-center">
            <Checkbox />
            <span className="pl-2">{t("components.newest")}</span>
          </span>
          <span className="b flex items-center">
            <Checkbox />
            <span className="pl-2">{t("components.rearest")}</span>
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default React.memo(CommentSection);
