import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import duration from "dayjs/plugin/duration";

dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(duration);

export const calculateOdd = (amountA: number, amountB: number) => {
  if (amountA === 0 || amountB === 0) return "-";
  return (amountA / amountB + 1).toFixed(2);
};

export const calculatePotentialWinnings = (
  amount: number,
  positionIsA: boolean,
  amountA: number,
  amountB: number
) => {
  // TODO: fix accuracy with BigNumber?
  const prizePool = amount * 10 ** 6 + amountA + amountB;
  const odds = positionIsA
    ? amountA / (amountB + amount * 10 ** 6) + 1
    : amountB / (amountA + amount * 10 ** 6) + 1;
  return odds == Infinity ? "0.00" : (odds * amount).toFixed(2);
};

export const resolveImage = (
  imgPath: string | null,
  dimensions: string = "600x400"
) => imgPath || `https://placehold.co/${dimensions}`;

export * from "./auth";
export * from "./capFirst";
export * from "./convert";
export * from "./delay";
export * from "./formatDate";
export * from "./getLoginType";
export * from "./getWalletInstance";
export * from "./inheritClassName";
export * from "./number";
export * from "./fetchImage";
