import { queryKeys } from "@/api/constants";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  UseInfiniteQueryResult,
} from "@tanstack/react-query";
import {
  Battle,
  BattleComment,
  BattleDetails,
  CommentPayload,
  PagedDataResponse,
} from "@/types";
import { client } from "../base";

export interface InfiniteBattlesQueryResponse {
  pages: PagedDataResponse<Battle>[];
  pageParams: number[];
}

export const getBattles =
  (): UseInfiniteQueryResult<InfiniteBattlesQueryResponse> => {
    return useInfiniteQuery({
      queryKey: [queryKeys.BATTLES],
      queryFn: ({ pageParam }: { pageParam: number }) =>
        client
          .setConfig({ params: { page: pageParam } })
          .get<PagedDataResponse<Battle>>("battles"),
      staleTime: 0,
      gcTime: 0,
      initialPageParam: 1,
      getNextPageParam: (response) => {
        const nextPage = response.current_page + 1;
        return nextPage > response.total_pages ? null : nextPage;
      },
    });
  };

export const getBattle = (id: string) =>
  useQuery<BattleDetails>({
    queryKey: [queryKeys.BATTLE, id],
    queryFn: () => client.clearParams().get<BattleDetails>(`battles/${id}`),
    staleTime: 10_000,
  });

export const getBattleComments = (id: string | undefined, page: number = 1) =>
  useQuery<PagedDataResponse<BattleComment>>({
    queryKey: [queryKeys.BATTLE_COMMENTS],
    queryFn: () =>
      client
        .setConfig({ params: { page, pageSize: 10 } })
        .get<PagedDataResponse<BattleComment>>(`battles/${id}/comments`),
    staleTime: 10_000,
  });

export const postBattleComment = (id: string) =>
  useMutation({
    mutationFn: (comment: CommentPayload) =>
      client
        .clearParams()
        .post<
          PagedDataResponse<BattleComment[]>
        >(`battles/${id}/comment`, comment),
  });

interface MyBattlesProps {
  past?: boolean;
}

export const useMyBattles = ({
  past = false,
}: MyBattlesProps): UseInfiniteQueryResult<InfiniteBattlesQueryResponse> =>
  useInfiniteQuery({
    queryKey: [queryKeys.MY_BATTLES, past],
    queryFn: ({ pageParam }: { pageParam: number }) =>
      client
        .setConfig({ params: { page: pageParam, pageSize: 10, past } })
        .get<PagedDataResponse<Battle>>("battles/user"),
    staleTime: 10_000,
    initialPageParam: 1,
    getNextPageParam: (response) => {
      const nextPage = response.current_page + 1;
      return nextPage > response.total_pages ? null : nextPage;
    },
  });
