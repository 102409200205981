import { DateDiff, WeekSelect } from "@/types";
import dayjs from "dayjs";

const getDaySuffix = (day: number): string => {
  if (day >= 11 && day <= 13) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDate = (date: Date | string, format?: string): string => {
  const day =
    typeof date === "string" ? new Date(date).getDate() : date.getDate();
  const suffix = getDaySuffix(day);
  return dayjs(date).format(format ?? `D[${suffix}] MMM YYYY, HH:mm`);
};

export const formatTimestamp = (
  timestamp: number,
  format: "short" | "long" = "long"
) =>
  format === "long"
    ? dayjs(timestamp).format("DD MMM YYYY") // "05 Dec 2024"
    : dayjs(timestamp).format("DD MMM"); // "05 Dec"

/**
 * @description Finds difference between two dates
 * @param startDate UTC data-time string
 * @param endDate UTC data-time string
 * @returns { days: number, minutes: number, hours: number }
 * @example getDiff('2025-12-01T00:00:00.000Z', '2025-12-01T00:00:00.000Z')
 */
export const getDiff = (startDate: string, endDate: string): DateDiff => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const hoursDiff = end.diff(start, "h");
  const minutesDiff = end.diff(start, "m");
  const days = Math.floor(hoursDiff / 24);
  const hours = hoursDiff - days * 24;
  const minutes = minutesDiff - Math.floor(hoursDiff * 60);

  return {
    days,
    hours,
    minutes,
  };
};

export const getDiffFrom = (date: string) => {
  const from = dayjs(date);
  return from.format("DD/MM/YYYY, HH:mm:ss");
};

/**
 * @description Converts a date to its corresponding week
 * number in the calendar year.
 * @param date - UTC data-time string
 * @returns The week number (1-53) in the calendar year
 * @example dateToWeekOfYear('2025-12-01T00:00:00.000Z')
 */
export const dateToWeekOfYear = (date: string) => dayjs(date).week();

export const dateRelative = (date: string) =>
  dayjs().isBefore(dayjs(date))
    ? dayjs().from(dayjs(date), true)
    : dayjs().to(dayjs(date));

export const getTimeUntil = (
  targetDate: string,
  { seconds }: { seconds?: boolean } = { seconds: false }
) => {
  const target = dayjs(targetDate);
  const now = dayjs();
  // Get the difference in milliseconds
  const diff = target.diff(now);
  // Convert to duration object
  const duration = dayjs.duration(diff);
  return {
    days: !seconds ? Math.floor(duration.days()) : undefined,
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: seconds ? duration.seconds() : undefined,
  };
};

/**
 * @description Gets current and next week data
 * @returns {WeekSelect} An object containing current and next week objects
 * @example getCurrentAndNextWeeks()
 */
export const getCurrentAndNextWeeks = (): WeekSelect => {
  const now = dayjs();
  const currentWeek = {
    week: now.week(),
    start: now.startOf("week").format("DD MMM"),
    end: now.endOf("week").format("DD MMM"),
  };
  const nextWeek = {
    week: now.endOf("week").add(1, "d").week(),
    start: now.endOf("week").add(1, "d").startOf("w").format("DD MMM"),
    end: now.endOf("week").add(1, "d").endOf("w").format("DD MMM"),
  };

  return {
    currentWeek,
    nextWeek,
  };
};
