export const currentEnv: string = import.meta.env.VITE_ENV;
export const isDevelopment: boolean = currentEnv === "development";
export const isStaging: boolean = currentEnv === "staging";
export const isProduction: boolean = currentEnv === "production";

export const buildNumber: number = import.meta.env.VITE_BUILD_NUMBER;
export const pmBiconomyApiKey: string = import.meta.env
  .VITE_PM_BICONOMY_API_KEY;
export const pmAddress: string = import.meta.env.VITE_PM_ADDRESS;
export const apiURL: string = import.meta.env.VITE_API_URL;
export const transakApiKey: string = import.meta.env.VITE_TRANSAK_API_KEY;
export const s3BucketURL: string =
  currentEnv === "development"
    ? "https://dripit-images-dev.s3.eu-west-1.amazonaws.com/"
    : "https://dripit-images-stage.s3.eu-west-1.amazonaws.com/";
