import React, { useEffect } from "react";
import { LogOutIcon, LogInIcon } from "lucide-react";
import { useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core";

import AvatarIcon from "@/assets/svg/avatar.svg?react";
import { Button } from "@/components/ui";
import { useBreakpoints, useTranslate } from "@/hooks";
import { useSidebar } from "@/contexts/SidebarContext";
import { useDispatch, useSelector } from "react-redux";
import { authActions, RootState } from "@/store";

const User: React.FC = () => {
  const t = useTranslate();
  const { user, handleLogOut, setShowAuthFlow } = useDynamicContext();
  const isLoggedIn = useIsLoggedIn();
  const { isExpanded } = useSidebar();
  const { isGreaterThan } = useBreakpoints();
  const resolvedUsername = React.useMemo(
    () => (isLoggedIn && user ? user.username : "Guest"),
    [isLoggedIn, user]
  );
  const triggerLogin = useSelector(
    (state: RootState) => state.auth.triggerLogin
  );
  const dispatch = useDispatch();
  const triggerAuthFlow = async () => {
    await handleLogOut();
    setShowAuthFlow(true);
    dispatch(authActions.toggleTriggerLogin());
  };

  useEffect(() => {
    if (triggerLogin) {
      triggerAuthFlow();
    }
  }, [triggerLogin]);

  const handleAuthButton = async () => {
    if (isLoggedIn) {
      await handleLogOut();
    } else {
      setShowAuthFlow(true);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-2 pt-4 lg:flex-row xl:gap-4">
      <AvatarIcon className="size-10 xl:size-12 2xl:size-16" />
      {(isExpanded || (!isExpanded && !isGreaterThan("lg"))) && (
        <div className="flex w-full flex-col items-center lg:items-start">
          {isLoggedIn && (
            <p className="mb-1 text-center text-xs lg:text-start lg:text-base xl:text-lg">
              {resolvedUsername}
            </p>
          )}
          <Button
            variant="ghost"
            className="flex h-auto items-center gap-2 px-0 py-0 text-xs font-normal text-slate-400 hover:bg-transparent hover:text-slate-400 focus:text-slate-400 lg:text-sm"
            onClick={handleAuthButton}>
            {isLoggedIn ? (
              <>
                {t("auth.sign_out")}
                <LogOutIcon className="hidden !size-3.5 lg:flex lg:size-4" />
              </>
            ) : (
              <>
                {t("auth.sign_in")}
                <LogInIcon className="hidden !size-3.5 lg:flex lg:size-4" />
              </>
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(User);
