import React from "react";
import { axiosInstance, client } from "@/api/base";
import { authActions, RootState } from "@/store";
import {
  deleteAuthTokens,
  getJWT,
  getRefreshToken,
  setJWT,
  setRefreshToken,
} from "@/helpers";
import { useDispatch, useSelector } from "react-redux";
import { queryClient } from "@/contexts/ReactQuery";
import isEmpty from "lodash.isempty";

const useAuth = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const refreshToken = useSelector(
    (state: RootState) => state.auth.refreshToken
  );
  const [isInitialized, setIsInitialized] = React.useState(false);

  // Set required data on App's first mount
  React.useEffect(() => {
    const accesToken = getJWT();
    const refreshToken = getRefreshToken();
    if (accesToken && refreshToken) {
      client.setHeaders({ Authorization: `Bearer ${accesToken}` });
      dispatch(authActions.setAccessToken(accesToken));
      dispatch(authActions.setRefreshToken(refreshToken));
    }
    setIsInitialized(true);
  }, []);

  React.useEffect(() => {
    if (accessToken && refreshToken) {
      setJWT(accessToken);
      client.setHeaders({ Authorization: `Bearer ${accessToken}` });
      axiosInstance.defaults.headers.common["Authorization"] =
        `Bearer ${accessToken}`;
      queryClient.invalidateQueries({ refetchType: "all" });
      setRefreshToken(refreshToken);
    }
    if (!accessToken && !refreshToken) {
      client.clearHeaders();
      delete axiosInstance.defaults.headers.common["Authorization"];
      deleteAuthTokens();
      queryClient.removeQueries();
    }
  }, [accessToken, refreshToken]);

  return { isAuthenticated: !isEmpty(accessToken), isInitialized };
};

export default useAuth;
