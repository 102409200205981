import { ActivitiesEnum } from "@dripit/constants";

export enum WalletType {
  SMART_ACCOUNT = "SMART_ACCOUNT", // social wallet / use biconomy
  EOA = "EOA", // web3 wallet / use dynamic
  NONE = "NONE",
}

interface ArtistData {
  id: number;
  spotify_id: string;
  name: string;
  slug: string;
  image: string;
  bio: string | null;
  wins_count: number;
  losses_count: number;
  updated_at: string;
}

interface BattleData {
  id: number;
  onchainId: number;
  type: number;
  status: number;
  name: string;
  sideA_id: number;
  sideB_id: number;
  sideA: ArtistData;
  sideB: ArtistData;
  winner: ArtistData | null;
}

interface TransactionData {
  id: number;
  battle_id: number;
  user_id: number;
  side: boolean;
  amount: string;
  created_at: string;
  won: boolean;
  updated_at: string;
  battles?: BattleData;
}

export interface WalletActivity {
  id: number;
  actor_id: number;
  type: ActivitiesEnum;
  entity_id: number | null;
  entity_type: number | null;
  amount: number | null;
  created_at: string;
  data?: TransactionData;
}
