import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { cn } from "@/helpers/tailwind";

import isEmpty from "lodash.isempty";
import { UrlParams } from "@/constants";
import Sidebar from "@/components/layout/Sidebar";
import MobileTopNav from "@/components/layout/MobileTopNav";
import MobileBottomNav from "@/components/layout/MobileBottomNav";
import { ProfileScreens } from "@/pages/profile/components/Menu";
import { useSidebar } from "@/contexts/SidebarContext";
import { useAuth, useWalletInitialization } from "@/hooks";

const PATHS_WITH_OWN_SCROLLING = ["/battles"];

const Root: React.FC = () => {
  const { isExpanded } = useSidebar();
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();
  const location = useLocation();
  useAuth();
  useWalletInitialization();

  const avoidBottomNavSpacing = () =>
    PATHS_WITH_OWN_SCROLLING.includes(location.pathname);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const ticketsTab =
      searchParams.get(UrlParams.PROFILE_TAB) === ProfileScreens.Tickets;
    const ticketQR = searchParams.get(UrlParams.TICKET_QR);
    const isInTicketsScreen =
      window.location.pathname === "/profile" && ticketsTab;

    if (!isEmpty(ticketQR) && isLoggedIn && !isInTicketsScreen) {
      navigate(
        `/profile?${UrlParams.PROFILE_TAB}=${ProfileScreens.Tickets}&${UrlParams.TICKET_QR}=${ticketQR}`,
        { replace: true }
      );
    }
  }, [isLoggedIn]);

  return (
    <div className="md:px-[1%]">
      <div className="relative flex h-dvh md:gap-[1%]">
        <aside
          className={cn(
            "hidden h-full py-4 transition-all duration-300 ease-in-out md:block",
            {
              "w-[16.666%]": isExpanded,
              "w-[8.333%]": !isExpanded,
            }
          )}>
          <Sidebar />
        </aside>
        <MobileTopNav className="mobile-top-nav-height fixed left-0 top-0 md:hidden" />
        <main
          className={cn(
            "scrollbar max-h-dvh w-full overflow-y-auto transition-all duration-300 ease-in-out md:pt-4",
            {
              "md:w-[83.333%]": isExpanded,
              "md:w-[91.666%]": !isExpanded,
              "bottom-nav-spacing": !avoidBottomNavSpacing(),
            }
          )}>
          <div className="mobile-top-nav-height md:hidden" />
          <Outlet />
        </main>
        <MobileBottomNav />
      </div>
    </div>
  );
};

export default React.memo(Root);
