import React from "react";
import { cn } from "@/helpers/tailwind";
import { LucideIcon } from "lucide-react";

import { Toggle } from "@/components/ui/toggle";
import { ToggleGroup } from "@/components//ui/toggle-group";
import { useBreakpoints, useTranslate } from "@/hooks";
import { inheritClassName } from "@/helpers";

type EnumType = string | number;

interface Item<T extends EnumType> {
  id: T;
  labelKey: string;
  Icon?: LucideIcon | React.FC<{ className?: string }>;
}

interface Props<T extends EnumType> {
  className?: string;
  hideLabelOnMobile?: boolean;
  items: Item<T>[];
  selectedId: Item<T>["id"];
  onChange: (id: Item<T>["id"]) => void;
}

const ToggleFilter = <T extends EnumType>({
  className,
  hideLabelOnMobile,
  items,
  selectedId,
  onChange,
}: Props<T>) => {
  const t = useTranslate();
  const { isGreaterThan } = useBreakpoints();

  return (
    <ToggleGroup
      type="single"
      className={cn("flex rounded-md bg-slate-700 p-1", className)}>
      {items.map(({ id, labelKey, Icon }) => (
        <Toggle
          key={id}
          pressed={selectedId === id}
          onPressedChange={() => onChange(id)}
          size={isGreaterThan("md") ? "default" : "xs"}
          className={cn(
            "flex h-full rounded-md data-[state=on]:bg-slate-900 data-[state=on]:text-white [&_span]:data-[state=on]:text-white",
            inheritClassName("rounded-md", className),
            inheritClassName("rounded-full", className),
            { "px-1.5 md:px-2 lg:px-3": hideLabelOnMobile }
          )}>
          <div className="flex items-center space-x-2">
            {Icon && (
              <span className="text-slate-400">
                <Icon className={cn("size-4 lg:size-5")} />
              </span>
            )}
            <span
              className={cn("text-sm text-slate-400", {
                "hidden lg:block": hideLabelOnMobile,
              })}>
              {t(labelKey)}
            </span>
          </div>
        </Toggle>
      ))}
    </ToggleGroup>
  );
};

export default React.memo(ToggleFilter) as typeof ToggleFilter;
