import React from "react";
import { formatCurrency, usdcToUsd } from "@/helpers";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { s3BucketURL } from "@/helpers/environment";

interface Props {
  title: string;
  value: number;
  subtitle?: string | undefined;
  variant?: "gray" | "indigo" | "teal" | "red";
}

// Todo with BATTLES-316: Fix text overflow & refactor to only use SVG for the flag icon, rest should be done with CSS.
const StatBox: React.FC<Props> = ({
  title,
  value,
  subtitle,
  variant = "gray",
}) => {
  const resolveVariant = () => {
    switch (variant) {
      case "indigo":
        return s3BucketURL + "My-Stake-Indigo.svg";
      case "red":
        return s3BucketURL + "My-Stake-Red.svg";
      case "teal":
        return s3BucketURL + "My-Stake-Teal.svg";
      case "gray":
        return s3BucketURL + "Prize-Pool-Gray.svg";

      default:
        return s3BucketURL + "Prize-Pool-Gray.svg";
    }
    return s3BucketURL + "Prize-Pool-Gray.svg";
  };
  return (
    <div
      style={{
        backgroundImage: `url(${resolveVariant()})`,
      }}
      className={`relative grid place-content-center bg-cover text-sm font-semibold ${variant !== "gray" ? "md:h-16 md:w-14 lg:h-24 lg:w-20" : "md:h-14 md:w-14 lg:h-20 lg:w-20"}`}>
      <span
        className={`${variant !== "gray" ? "mt-3 md:mt-2" : "mt-5"} md:text-xs lg:text-sm`}>
        {formatCurrency(usdcToUsd(value))}
      </span>
      {subtitle && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <span
                className={
                  "absolute truncate md:left-2 md:top-11 md:text-[0.6rem] lg:left-4 lg:top-16 lg:text-sm"
                }>
                {subtitle}
              </span>
            </TooltipTrigger>
            <TooltipContent className="border border-slate-400 bg-secondary">
              <div className="space-x-4 text-sm">{subtitle}</div>
            </TooltipContent>
          </Tooltip>
          g
        </TooltipProvider>
      )}
    </div>
  );
};

export default React.memo(StatBox);
