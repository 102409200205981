import React from "react";
import { Wallet } from "@dynamic-labs/sdk-react-core";
import { BiconomySmartAccountV2 } from "@biconomy/account";

type WalletInstance = Wallet | BiconomySmartAccountV2 | null;

interface WalletContextType {
  walletInstance: WalletInstance;
  setWalletInstance: (wallet: WalletInstance) => void;
}

const WalletContext = React.createContext<WalletContextType | undefined>(
  undefined
);

export const WalletProvider = ({ children }: { children: React.ReactNode }) => {
  const [walletInstance, setWalletInstance] =
    React.useState<WalletInstance>(null);

  return (
    <WalletContext.Provider value={{ walletInstance, setWalletInstance }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWalletInstance = () => {
  const context = React.useContext(WalletContext);
  if (context === undefined) {
    throw new Error("useWallet must be used within a WalletProvider");
  }
  return context;
};
