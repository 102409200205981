import React from "react";

import { formatCurrency, usdcToUsd } from "@/helpers";
import { s3BucketURL } from "@/helpers/environment";
import { useBreakpoints } from "@/hooks";

interface Props {
  variation?: "indigo" | "red" | "teal";
  value: number;
}

const RoundToken: React.FC<Props> = ({ variation = "indigo", value = 0 }) => {
  const { isGreaterThan } = useBreakpoints();

  const resolveVariant = () => {
    switch (variation) {
      case "indigo":
        return s3BucketURL + "Token-Indigo.svg";
      case "red":
        return s3BucketURL + "Token-Red.svg";
      case "teal":
        return s3BucketURL + "Token-Teal.svg";

      default:
        return s3BucketURL + "Token-Indigo.svg";
    }
  };

  return (
    <span
      style={{ backgroundImage: `url(${resolveVariant()})` }}
      className="grid size-10 place-content-center bg-cover font-phonk text-[10px] font-semibold leading-[1] lg:size-12 lg:text-[11px]">
      {formatCurrency(usdcToUsd(value), isGreaterThan("lg") ? 2 : 0)}
    </span>
  );
};

export default React.memo(RoundToken);
