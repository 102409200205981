export const endpointsToExcludeTheJWT = [];

export const queryKeys = {
  PROFILE: "profile",
  BATTLES: "battles",
  BATTLE: "battle",
  BATTLE_COMMENTS: "battle_comments",
  POST_BATTLE_COMMENT: "post_battle_comment",
  WALLET_ACTIVITIES: "wallet_activities",
  MY_BATTLES: "my_battles",
  SPOTIFY_TOKEN: "spotify_token",
  SPOTIFY_SEARCH: "spotify_search",
};
