import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  accessToken: null | string;
  refreshToken: null | string;
  triggerLogin: boolean;
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
  triggerLogin: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string | null>) => {
      state.refreshToken = action.payload;
    },
    clearTokens: (state) => {
      state.refreshToken = null;
      state.accessToken = null;
    },
    toggleTriggerLogin: (state) => {
      state.triggerLogin = !state.triggerLogin;
    },
  },
});

export const actions = authSlice.actions;

export default authSlice.reducer;
