import React from "react";
import useTranslate from "@/hooks/useTranslate";
import DynamicIcon, { IconProps } from "./DynamicIcon";

interface CenteredMessageProps {
  message?: string;
  icon?: IconProps["name"];
}

const CenteredMessage: React.FC<CenteredMessageProps> = ({ message, icon }) => {
  const t = useTranslate();

  return (
    <div className="flex h-full w-full flex-col items-center justify-center text-center">
      {icon && <DynamicIcon name={icon} />}
      <h3 className="flex-grow">{message ?? t("components.no_results")}</h3>
    </div>
  );
};

export default React.memo(CenteredMessage);
