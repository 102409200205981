import { Wallet } from "@dynamic-labs/sdk-react-core/src/lib/shared";

import {
  ContractName,
  generatePermitSignature,
  getAbi,
  getContractAddress,
  getContractName,
  getUSDCContractAddress,
} from "..";
import { prepareTxnArgs } from ".";
import { ContractFunc, ContractTxDataMap, MakePredictionArgs } from "@/types";

interface SubmitTxnEOAArgs<TData> {
  functionName: ContractFunc;
  wallet: Wallet;
  walletAddress: `0x${string}`;
  txnSpendsMoney: boolean;
  txData?: ContractTxDataMap[ContractFunc];
  setData: (data: TData | { success: boolean } | null) => void;
  setError: (error: Error | string | null) => void;
  setLoading: (loading: boolean) => void;
}

export const submitTxnEOA = async <TData>(args: SubmitTxnEOAArgs<TData>) => {
  const {
    functionName,
    wallet,
    walletAddress,
    txnSpendsMoney,
    txData,
    setData,
    setError,
    setLoading,
  } = args;
  setError(null);
  setLoading(true);

  let permitSig: string | undefined;

  if (txnSpendsMoney) {
    console.log({ mstatus: `${functionName} / loading permit sig...` });
    try {
      permitSig = await generatePermitSignature({
        primaryWallet: wallet,
        tokenAddress: getUSDCContractAddress(),
        walletAddress,
        spenderAddress: getContractAddress(functionName),
        amount: (txData as MakePredictionArgs).amount ?? 0,
      });
    } catch (error) {
      console.error(error);
    }
  }

  try {
    const mainTxnArgs = prepareTxnArgs<typeof functionName>(
      functionName,
      walletAddress,
      { ...txData, permitSig } as ContractTxDataMap[typeof functionName]
    );
    console.log({
      mstatus: `${functionName} / EOA / loading...`,
      mainTxnArgs,
    });
    const walletClient = await wallet.getWalletClient();
    const publicClient = await wallet.getPublicClient();

    const hash = await walletClient.writeContract({
      address: getContractAddress(functionName),
      account: walletAddress,
      abi: getAbi(functionName),
      functionName,
      args: mainTxnArgs,
    });
    console.log({
      mstatus: `${functionName} / EOA / hash`,
      hash,
      mainTxnArgs,
    });
    const receipt = await publicClient.waitForTransactionReceipt({
      hash,
    });
    const contractName = getContractName(functionName);
    console.log({
      mstatus: `${functionName} / EOA / receipt`,
      receipt,
      contractName,
    });

    if (contractName === ContractName.WEEKLY_BATTLES) {
      if (receipt.status === "success") {
        setData(receipt);
      } else {
        setError(`Error on ${functionName}: Receipt not success`);
        console.log({
          mstatus: `${functionName} / EOA / receipt - error`,
          receipt,
        });
      }
    } else {
      setData(receipt);
    }
  } catch (error) {
    setError(`Error on ${functionName}: ${error}`);
    console.error(`Error on ${functionName}:`, error);
  } finally {
    setLoading(false);
  }
};
