import { USDC_DECIMALS } from "@/constants";

export const usdcToUsd = (amount: number | string) => {
  const value = typeof amount === "string" ? Number(amount) : amount;
  return value / 10 ** USDC_DECIMALS;
};

export const usdToUsdc = (amount?: number | string) => {
  const value = typeof amount === "string" ? Number(amount) : (amount ?? 0);
  return Math.floor(value * 10 ** USDC_DECIMALS);
};
