import {
  MAINNET_USDC_ADDRESS,
  TESTNET_USDC_ADDRESS,
  USDC_ABI,
  WEEKLY_BATTLES_ABI,
} from "@/constants";
import { isProduction } from "@/helpers/environment";
import { ContractFunc } from "@/types";

export const getUSDCContractAddress = () =>
  isProduction ? MAINNET_USDC_ADDRESS : TESTNET_USDC_ADDRESS;

export const getAbi = (functionName: ContractFunc) => {
  if (
    [ContractFunc.APPROVE_USDC, ContractFunc.WITHDRAW_USDC].includes(
      functionName
    )
  ) {
    return USDC_ABI;
  }
  return WEEKLY_BATTLES_ABI;
};

export const getContractAddress = (functionName: ContractFunc) => {
  if (
    [ContractFunc.APPROVE_USDC, ContractFunc.WITHDRAW_USDC].includes(
      functionName
    )
  ) {
    return getUSDCContractAddress();
  }
  return import.meta.env.VITE_BATTLES_CONTRACT_ADDRESS;
};

export enum ContractName {
  USDC = "USDC",
  WEEKLY_BATTLES = "WEEKLY_BATTLES",
}

export const getContractName = (functionName: ContractFunc) => {
  const ca = getContractAddress(functionName);
  if (ca === import.meta.env.VITE_BATTLES_CONTRACT_ADDRESS) {
    return ContractName.WEEKLY_BATTLES;
  }
  return ContractName.USDC;
};
