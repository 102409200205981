import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CircleArrowLeftIcon } from "lucide-react";

import { useTranslate } from "@/hooks";
import { RootState } from "@/store";
import { AvatarBadge } from "../main";
import FullLogoIcon from "@/assets/svg/fullLogo.svg?react";
import { cn } from "@/helpers/tailwind";

interface MobileTopNavProps {
  className?: string;
}

const MobileTopNav: React.FC<MobileTopNavProps> = ({ className }) => {
  const t = useTranslate();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: profile, isLoading } = useSelector(
    (state: RootState) => state.profile
  );
  const isSubpath = React.useMemo(
    () => (location.pathname.replace(/\/$/, "").match(/\//g) || []).length > 1,
    [location.pathname]
  );

  return (
    <div
      className={cn(
        "z-40 flex w-full items-center justify-between gap-2 bg-background px-8 py-6",
        className
      )}>
      {isSubpath ? (
        <button
          className="flex items-center gap-2"
          onClick={() => navigate(-1)}>
          <CircleArrowLeftIcon className="size-8 stroke-1" />
          <span className="font-phonk text-2xl">{t("common.nav.back")}</span>
        </button>
      ) : (
        <FullLogoIcon className="h-8" />
      )}
      <AvatarBadge
        alt="avatar"
        src={profile?.avatar ?? null}
        loading={isLoading}
        className="size-10"
        ringClassName="border-slate-300 -m-1"
      />
    </div>
  );
};

export default MobileTopNav;
