import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";

import isNil from "lodash.isnil";
import UsdcIcon from "@/assets/svg/usdc.svg?react";
import { cn } from "@/helpers/tailwind";
import { useTheme } from "@/contexts/Theme";
import { useSidebar } from "@/contexts/SidebarContext";
import { useWeb3Query, useTranslate } from "@/hooks";
import { ContractFunc } from "@/types";
import { RootState, walletActions } from "@/store";
import { useWalletInstance } from "@/contexts/WalletContext";
import { Skeleton } from "@/components/ui";
import { formatBalance, formatCurrency } from "@/helpers/number";
import { WithTooltip } from "../main";

const TokenBalance = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const { walletInstance } = useWalletInstance();
  const { theme } = useTheme();
  const { isExpanded } = useSidebar();
  const isDark = theme === "dark";
  const isLoggedIn = useIsLoggedIn();
  const { walletAddress } = useSelector((state: RootState) => state.wallet);
  const [getUsdcBalance, { loading, data: balance }] = useWeb3Query<string>(
    ContractFunc.GET_USDC_BALANCE
  );

  React.useEffect(() => {
    let intervalId: NodeJS.Timeout;
    const callAsync = async (): Promise<void> => {
      try {
        await getUsdcBalance();
      } catch (error) {
        console.error("Error fetching USDC balance:", error);
      }
    };

    if (isLoggedIn && walletAddress && walletInstance) {
      callAsync();
      intervalId = setInterval(callAsync, 7000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // TODO: figure out how to remove walletInstance from the dependency array
  }, [isLoggedIn, walletAddress, walletInstance]);

  React.useEffect(() => {
    if (balance) {
      dispatch(walletActions.setBalance(balance as string | null));
    }
  }, [balance]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div
      className={cn("mt-8 flex w-full items-center rounded-lg p-3", {
        "bg-slate-800 text-slate-400": isDark,
        "bg-slate-200 text-slate-600": !isDark,
        "flex-col": !isExpanded,
      })}>
      {isExpanded && (
        <span className="flex-grow text-sm">{t("menu.balance")}</span>
      )}
      <div className={cn({ "mb-2": !isExpanded })}>
        <UsdcIcon
          className={cn("size-4", {
            "text-lightblue": isDark,
            "text-[#94A3B8]": !isDark,
          })}
        />
      </div>
      <span
        className={cn("text-sm font-bold", {
          "ml-2": isExpanded,
          "text-white": isDark,
          "text-gray-900": !isDark,
        })}>
        {loading && isNil(balance) ? (
          <Skeleton className="h-4 w-8 bg-slate-200/30" />
        ) : (
          <WithTooltip tooltipContent={`$${formatCurrency(Number(balance))}`}>
            {formatBalance(Number(balance))}
          </WithTooltip>
        )}
      </span>
    </div>
  );
};

export default TokenBalance;
